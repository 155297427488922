import React from 'react'
import { Link } from 'react-router-dom'

import { Player } from '@lottiefiles/react-lottie-player'
import { Helmet } from 'react-helmet'

import HeaderPage from '../components/header-page'
import FooterPage from '../components/footer-page'
import './homepage.css'

const Homepage = (props) => {
  return (
    <div className="homepage-container">
      <Helmet>
        <title>B3Science</title>
        <meta
          name="description"
          content="Laboratory of Brain Body and Behavior. Courses and resources for cognitive and computational neuroscience analysis."
        />
        <meta property="og:title" content="B3Science" />
        <meta
          property="og:description"
          content="Laboratory of Brain Body and Behavior. Courses and resources for cognitive and computational neuroscience analysis."
        />
      </Helmet>
      <HeaderPage rootClassName="header-page-root-class-name"></HeaderPage>
      <div className="homepage-container01">
        <div className="homepage-hero">
          <div className="homepage-container02">
            <div className="homepage-container03">
              <h1 className="homepage-text">
                <span className="homepage-text01">Brain</span>
                <br></br>
                <span className="homepage-text03">Body</span>
                <br></br>
                <span className="homepage-text05">Behavior</span>
                <br></br>
              </h1>
            </div>
            <span className="homepage-text07">
              Studing the way the human brain and body adapt their behavior to
              environmental challenges over time, on the scale of milliseconds,
              minutes, hours, days, and across the lifespan. Understanding
              cognition and mental process trhough neural sciences to address
              basic science questions as well as clinical applications.
            </span>
          </div>
          <img
            alt="image"
            src="/lb3logolottie-400h.gif"
            className="homepage-image"
          />
          <img
            alt="image"
            src="/Branding/logo1-400h.webp"
            className="homepage-image1"
          />
          <Player
            src="https://www.lottielab.com/editor?file=4b63a57c-72b2-4308-9bd1-10ff22e7551c"
            speed="1"
            autoplay="true"
            background="transparent"
            className="homepage-lottie-node"
          ></Player>
        </div>
      </div>
      <div className="homepage-features">
        <h1 className="homepage-text08">
          <span className="homepage-text09">
            Discover
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="homepage-text10">our</span>
          <br></br>
          <span className="homepage-text12">courses</span>
        </h1>
        <div className="homepage-separator"></div>
        <div className="homepage-container04">
          <div className="homepage-container05">
            <div className="homepage-feature-card">
              <svg viewBox="0 0 1024 1024" className="homepage-icon">
                <path d="M662 470q26 0 45-19t19-45-19-45-45-19-45 19-19 45 19 45 45 19zM512 768q72 0 130-47t84-123h-428q26 76 84 123t130 47zM362 342q-26 0-45 19t-19 45 19 45 45 19 45-19 19-45-19-45-45-19zM512 86q176 0 301 125t125 301-125 301-301 125-301-125-125-301 125-301 301-125z"></path>
              </svg>
              <h2 className="homepage-text13">
                Discover the advances in emotion and cognition
              </h2>
            </div>
            <div className="homepage-feature-card1">
              <svg viewBox="0 0 1024 1024" className="homepage-icon2">
                <path d="M956.29 804.482l-316.29-527.024v-213.458h32c17.6 0 32-14.4 32-32s-14.4-32-32-32h-320c-17.6 0-32 14.4-32 32s14.4 32 32 32h32v213.458l-316.288 527.024c-72.442 120.734-16.512 219.518 124.288 219.518h640c140.8 0 196.73-98.784 124.29-219.518zM241.038 640l206.962-344.938v-231.062h128v231.062l206.964 344.938h-541.926z"></path>
              </svg>
              <h2 className="homepage-text14">
                Explore the usual tools employed in cognitive neuroscience
                laboratories
              </h2>
            </div>
            <div className="homepage-feature-card2">
              <svg viewBox="0 0 1024 1024" className="homepage-icon4">
                <path d="M128 896h896v128h-1024v-1024h128zM288 832c-53.020 0-96-42.98-96-96s42.98-96 96-96c2.828 0 5.622 0.148 8.388 0.386l103.192-171.986c-9.84-15.070-15.58-33.062-15.58-52.402 0-53.020 42.98-96 96-96s96 42.98 96 96c0 19.342-5.74 37.332-15.58 52.402l103.192 171.986c2.766-0.238 5.56-0.386 8.388-0.386 2.136 0 4.248 0.094 6.35 0.23l170.356-298.122c-10.536-15.408-16.706-34.036-16.706-54.11 0-53.020 42.98-96 96-96s96 42.98 96 96c0 53.020-42.98 96-96 96-2.14 0-4.248-0.094-6.35-0.232l-170.356 298.124c10.536 15.406 16.706 34.036 16.706 54.11 0 53.020-42.98 96-96 96s-96-42.98-96-96c0-19.34 5.74-37.332 15.578-52.402l-103.19-171.984c-2.766 0.238-5.56 0.386-8.388 0.386s-5.622-0.146-8.388-0.386l-103.192 171.986c9.84 15.068 15.58 33.060 15.58 52.4 0 53.020-42.98 96-96 96z"></path>
              </svg>
              <h2 className="homepage-text15">
                Learn new procedures in statistical and signal processing
              </h2>
            </div>
            <div className="homepage-feature-card3">
              <svg viewBox="0 0 1024 1024" className="homepage-icon6">
                <path d="M288 384h-192c-17.6 0-32 14.4-32 32v576c0 17.6 14.4 32 32 32h192c17.6 0 32-14.4 32-32v-576c0-17.6-14.4-32-32-32zM288 960h-192v-256h192v256zM608 256h-192c-17.6 0-32 14.4-32 32v704c0 17.6 14.4 32 32 32h192c17.6 0 32-14.4 32-32v-704c0-17.6-14.4-32-32-32zM608 960h-192v-320h192v320zM928 128h-192c-17.6 0-32 14.4-32 32v832c0 17.6 14.4 32 32 32h192c17.6 0 32-14.4 32-32v-832c0-17.6-14.4-32-32-32zM928 960h-192v-384h192v384z"></path>
              </svg>
              <h2 className="homepage-text16">
                Study how neuroimaging and peripheral meassurements can
                contribute to knowledge
              </h2>
            </div>
          </div>
          <img
            alt="image"
            src="/courseslogo-500h.webp"
            className="homepage-image2"
          />
        </div>
        <div className="homepage-container06">
          <div className="homepage-btn-group">
            <Link to="/courses" className="homepage-navlink button">
              <span>
                <span className="homepage-text18">Access to courses</span>
                <br></br>
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="homepage-hero1">
        <div className="homepage-container07">
          <div className="homepage-container08">
            <img
              alt="image"
              src="/group%2055-300h.webp"
              className="homepage-image3"
            />
            <div className="homepage-container09">
              <h1 className="homepage-text20">
                <span className="homepage-text21">Library</span>
                <br></br>
                <span>Toolbox</span>
                <br></br>
                <span>Package</span>
                <br></br>
              </h1>
              <span>Call it however you prefer, but use it!</span>
            </div>
          </div>
        </div>
        <div className="homepage-container10">
          <div className="homepage-container11">
            <div className="homepage-container12"></div>
          </div>
        </div>
        <span className="homepage-text28">
          <span>
            <span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non
              volutpat turpis.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
          <br></br>
          <span>
            <span>
              Mauris luctus rutrum mi ut rhoncus. Integer in dignissim tortor.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </span>
        </span>
        <div className="homepage-container13">
          <div className="homepage-btn-group1">
            <Link to="/resources" className="homepage-navlink1 button">
              <span className="homepage-text35">
                <span className="homepage-text36">Access to resources</span>
                <br></br>
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="homepage-banner">
        <h1 className="homepage-text38">
          <span className="homepage-text39">
            Our
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="homepage-text40">lines of</span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="homepage-text42">research</span>
        </h1>
        <span>
          In several directions, our efforts contribute to the understanding the
          science underlaying how brain, body, and behavior works.
        </span>
        <img
          alt="image"
          sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
          src="/lines2-1400w.webp"
          srcSet="/lines2-1400w.webp 1200w, /lines2-tablet.webp 800w, /lines2-mobile.webp 480w"
          className="homepage-image4"
        />
        <div className="homepage-container14">
          <div className="homepage-btn-group2">
            <Link to="/research" className="homepage-navlink2 button">
              <span>
                <span className="homepage-text45">Access to research</span>
                <br></br>
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="homepage-hero2">
        <div className="homepage-container15">
          <h1 className="homepage-text47">
            <span>
              About
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="homepage-text49">us</span>
          </h1>
          <span className="homepage-text50">
            <span>
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non
                volutpat turpis.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
            <span>
              <span>
                Mauris luctus rutrum mi ut rhoncus. Integer in dignissim tortor.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </span>
          <span>
            <span>
              We are placed at Gainesville in Florida (US) in the University of
              Florida. Feel free to reach out:
            </span>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <span>3047 Long Leaf Rd Gainesville, FL 32608</span>
            <br></br>
            <br></br>
            <span>akeil@ufl.edu</span>
            <br></br>
            <br></br>
            <span>(352) 392-2439</span>
            <br></br>
            <br></br>
            <br></br>
          </span>
          <img
            alt="image"
            sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
            src="/group%20391-1500h.webp"
            srcSet="/group%20391-1500h.webp 1200w, /group%20391-tablet.webp 800w, /group%20391-mobile.webp 480w"
            className="homepage-image5"
          />
          <div className="homepage-btn-group3">
            <Link to="/people" className="homepage-navlink3 button">
              Meet the people
            </Link>
          </div>
        </div>
        <img
          alt="image"
          sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
          src="/florida-1200w.webp"
          srcSet="/florida-1200w.webp 1200w, /florida-tablet.webp 800w, /florida-mobile.webp 480w"
          className="homepage-image6"
        />
      </div>
      <FooterPage></FooterPage>
    </div>
  )
}

export default Homepage
