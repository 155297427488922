import React from 'react'

import { Helmet } from 'react-helmet'

import HeaderPage from '../components/header-page'
import HeadTitle from '../components/head-title'
import FooterPage from '../components/footer-page'
import './resources.css'

const Resources = (props) => {
  return (
    <div className="resources-container">
      <Helmet>
        <title>Resources - B3Science</title>
        <meta
          name="description"
          content="Laboratory of Brain Body and Behavior. Courses and resources for cognitive and computational neuroscience analysis."
        />
        <meta property="og:title" content="Resources - B3Science" />
        <meta
          property="og:description"
          content="Laboratory of Brain Body and Behavior. Courses and resources for cognitive and computational neuroscience analysis."
        />
      </Helmet>
      <HeaderPage rootClassName="header-page-root-class-name7"></HeaderPage>
      <HeadTitle
        heading="Resources"
        rootClassName="head-title-root-class-name"
      ></HeadTitle>
      <div className="resources-container1">
        <div className="resources-hero">
          <div className="resources-container2">
            <span className="resources-text">
              <span>
                Brain signal analysis library:
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="resources-text02">LIBAS</span>
              <br></br>
              <br></br>
            </span>
            <img
              alt="image"
              src="/group%2055b-500h.webp"
              className="resources-image"
            />
            <span className="resources-text05">
              <br></br>
              <br></br>
              <span>
                Analysis of brain signal such as electrophysiology needs
                mathematical tools. Here, we present the LIBAS toolbox. This
                library is based on MATLAB programing language and is
                continuously evolving to offer better tools and functions for
                researchers in the field of brain imaging.
              </span>
              <br></br>
              <br></br>
              <span>
                A set of MATLAB Live Scripts are able showing examples of how
                tools works and presenting good practices of analysis.
              </span>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      <div className="resources-container3">
        <img alt="image" src="/github-200h.webp" className="resources-image1" />
        <a
          href="https://github.com/csea-lab"
          target="_blank"
          rel="noreferrer noopener"
          className="resources-link button"
        >
          <span>
            <span className="resources-text14">Access to GitHub </span>
            <br></br>
          </span>
        </a>
      </div>
      <FooterPage rootClassName="footer-page-root-class-name6"></FooterPage>
    </div>
  )
}

export default Resources
