import React from 'react'

import { Helmet } from 'react-helmet'

import HeaderPage from '../components/header-page'
import FooterPage from '../components/footer-page'
import './blank.css'

const Blank = (props) => {
  return (
    <div className="blank-container">
      <Helmet>
        <title>Blank - B3Science</title>
        <meta
          name="description"
          content="Laboratory of Brain Body and Behavior. Courses and resources for cognitive and computational neuroscience analysis."
        />
        <meta property="og:title" content="Blank - B3Science" />
        <meta
          property="og:description"
          content="Laboratory of Brain Body and Behavior. Courses and resources for cognitive and computational neuroscience analysis."
        />
      </Helmet>
      <HeaderPage rootClassName="header-page-root-class-name1"></HeaderPage>
      <div className="blank-container1">
        <div className="blank-hero">
          <div className="blank-container2">
            <div className="blank-container3"></div>
            <span className="blank-text">
              <span>
                Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem
                ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem
                ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem
                ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem
                ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem
                ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem
                ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem
                ipsum Lorem ipsum Lore
              </span>
              <br></br>
              <br></br>
              <span>
                Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem
                ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem
                ipsum Lorem ipsum Lorem ipsumm ipsum Lorem ipsum Lorem
                ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem
                ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum 
              </span>
            </span>
          </div>
        </div>
      </div>
      <FooterPage rootClassName="footer-page-root-class-name"></FooterPage>
    </div>
  )
}

export default Blank
