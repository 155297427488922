import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import HeaderPage from '../components/header-page'
import FooterPage from '../components/footer-page'
import './profile.css'

const Profile = (props) => {
  return (
    <div className="profile-container">
      <Helmet>
        <title>Profile - B3Science</title>
        <meta
          name="description"
          content="Laboratory of Brain Body and Behavior. Courses and resources for cognitive and computational neuroscience analysis."
        />
        <meta property="og:title" content="Profile - B3Science" />
        <meta
          property="og:description"
          content="Laboratory of Brain Body and Behavior. Courses and resources for cognitive and computational neuroscience analysis."
        />
      </Helmet>
      <HeaderPage rootClassName="header-page-root-class-name9"></HeaderPage>
      <div className="profile-banner">
        <h1 className="profile-text">Profile</h1>
      </div>
      <div className="profile-banner1">
        <img
          alt="image"
          src="https://play.teleporthq.io/static/svg/default-img.svg"
          className="profile-image"
        />
        <form className="profile-form">
          <div className="profile-container1">
            <textarea placeholder="placeholder" className="textarea"></textarea>
            <textarea placeholder="placeholder" className="textarea"></textarea>
          </div>
        </form>
        <Link to="/courses" className="profile-navlink button">
          <span>
            <span>Update</span>
            <br></br>
          </span>
        </Link>
      </div>
      <FooterPage rootClassName="footer-page-root-class-name8"></FooterPage>
    </div>
  )
}

export default Profile
