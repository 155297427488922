import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import HeaderPage from '../components/header-page'
import FooterPage from '../components/footer-page'
import './login.css'

const Login = (props) => {
  return (
    <div className="login-container">
      <Helmet>
        <title>Login - B3Science</title>
        <meta
          name="description"
          content="Laboratory of Brain Body and Behavior. Courses and resources for cognitive and computational neuroscience analysis."
        />
        <meta property="og:title" content="Login - B3Science" />
        <meta
          property="og:description"
          content="Laboratory of Brain Body and Behavior. Courses and resources for cognitive and computational neuroscience analysis."
        />
      </Helmet>
      <HeaderPage rootClassName="header-page-root-class-name3"></HeaderPage>
      <div className="login-banner">
        <h1 className="login-text">Login</h1>
      </div>
      <div className="login-banner1">
        <input type="text" placeholder="email" className="input" />
        <input type="text" placeholder="password" className="input" />
        <Link to="/courses" className="login-navlink button">
          <span>
            <span className="login-text2">Login</span>
            <br></br>
          </span>
        </Link>
      </div>
      <FooterPage rootClassName="footer-page-root-class-name2"></FooterPage>
    </div>
  )
}

export default Login
