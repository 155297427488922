import React from 'react'

import PropTypes from 'prop-types'

import './research-card.css'

const ResearchCard = (props) => {
  return (
    <div className={`research-card-blog-post-card ${props.rootClassName} `}>
      <img
        alt={props.imageAlt}
        src={props.imageSrc}
        className="research-card-image"
      />
      <div className="research-card-container">
        <span className="research-card-text">{props.label}</span>
        <h1 className="research-card-text1">{props.title}</h1>
        <div className="research-card-container1">
          <span className="research-card-text2">{props.description}</span>
        </div>
        <span className="research-card-text3">{props.author}</span>
      </div>
    </div>
  )
}

ResearchCard.defaultProps = {
  label: 'ENTERPRISE',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non volutpat turpis. Mauris luctus rutrum mi ut rhoncus. Integer in dignissim tortor. Lorem ipsum dolor sit amet, consectetur adipiscing ...',
  rootClassName: '',
  author: 'by Jon Doe 5 hours ago',
  imageSrc:
    'https://images.unsplash.com/photo-1554415707-6e8cfc93fe23?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fHdvcmtpbmclMjBkZXNrfGVufDB8fHx8MTYyNjI1MDYwMg&ixlib=rb-1.2.1&h=1200',
  imageAlt: 'image',
  title: 'Lorem ipsum dolor sit amet',
}

ResearchCard.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  rootClassName: PropTypes.string,
  author: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  title: PropTypes.string,
}

export default ResearchCard
