import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './footer-page.css'

const FooterPage = (props) => {
  return (
    <div className={`footer-page-footer-page ${props.rootClassName} `}>
      <footer className="footer-page-footer">
        <div className="footer-page-container">
          <Link to="/" className="footer-page-navlink">
            <img
              alt={props.imageAlt}
              src={props.imageSrc}
              className="footer-page-image"
            />
          </Link>
          <nav className="footer-page-nav">
            <Link to="/courses" className="footer-page-navlink1">
              {props.text}
            </Link>
            <Link to="/resources" className="footer-page-navlink2">
              {props.text1}
            </Link>
            <Link to="/research" className="footer-page-navlink3">
              {props.text2}
            </Link>
            <Link to="/people" className="footer-page-navlink4">
              {props.text3}
            </Link>
          </nav>
        </div>
        <div className="footer-page-separator"></div>
        <div className="footer-page-container1">
          <span className="footer-page-text">
            <span className="">
              Laboratory of
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="footer-page-text2">Brain</span>
            <span className="">,</span>
            <span className="">
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="footer-page-text5">Body</span>
            <span className="">
              , and
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="footer-page-text7">Behavior</span>
          </span>
        </div>
      </footer>
    </div>
  )
}

FooterPage.defaultProps = {
  text1: 'Resources',
  text3: 'People\n',
  text2: 'Research',
  imageSrc: '/Branding/lb3-1500h.webp',
  rootClassName: '',
  imageAlt: 'logo',
  text: 'Courses',
}

FooterPage.propTypes = {
  text1: PropTypes.string,
  text3: PropTypes.string,
  text2: PropTypes.string,
  imageSrc: PropTypes.string,
  rootClassName: PropTypes.string,
  imageAlt: PropTypes.string,
  text: PropTypes.string,
}

export default FooterPage
