import React from 'react'

import PropTypes from 'prop-types'

import './head-title.css'

const HeadTitle = (props) => {
  return (
    <div className={`head-title-banner ${props.rootClassName} `}>
      <h1 className="head-title-text">{props.heading}</h1>
    </div>
  )
}

HeadTitle.defaultProps = {
  rootClassName: '',
  heading: 'titleHead',
}

HeadTitle.propTypes = {
  rootClassName: PropTypes.string,
  heading: PropTypes.string,
}

export default HeadTitle
