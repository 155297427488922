import React from 'react'

import PropTypes from 'prop-types'

import './people-card.css'

const PeopleCard = (props) => {
  return (
    <div className={`people-card-testimonial-card ${props.rootClassName} `}>
      <img
        alt={props.profileAlt}
        src={props.profileSrc}
        className="people-card-image"
      />
      <div className="people-card-testimonial">
        <span className="people-card-text">{props.quote}</span>
        <span className="people-card-text1">{props.name}</span>
        <span className="people-card-text3">{props.name1}</span>
        <a href={props.curriculumvitae} className="people-card-textcv">Curriculum Vitae (CV)</a>
      </div>
    </div>
  )
}

PeopleCard.defaultProps = {
  quote:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lorem lorem, malesuada in metus vitae, scelerisque accumsan ipsum.',
  name: 'John Doe',
  profileAlt: 'profile',
  name1: 'John Doe',
  rootClassName: '',
  profileSrc:
    'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE0fHxwb3J0cmFpdHxlbnwwfHx8fDE2MjYzNzg5NzI&ixlib=rb-1.2.1&h=1200',
}

PeopleCard.propTypes = {
  quote: PropTypes.string,
  name: PropTypes.string,
  profileAlt: PropTypes.string,
  name1: PropTypes.string,
  rootClassName: PropTypes.string,
  profileSrc: PropTypes.string,
}

export default PeopleCard
