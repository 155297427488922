import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import Register from './views/register'
import Page from './views/page'
import Courses from './views/courses'
import Login from './views/login'
import People from './views/people'
import Research from './views/research'
import Blank from './views/blank'
import Homepage from './views/homepage'
import Resources from './views/resources'
import Profile from './views/profile'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Register} exact path="/register" />
        {/* <Route component={Page} path="**" /> */}
        <Route component={Courses} exact path="/courses" />
        <Route component={Login} exact path="/login" />
        <Route component={People} exact path="/people" />
        {/* <Route component={Research} exact path="/research" /> */}
        <Route component={Blank} exact path="/blank" />
        <Route component={Homepage} exact path="/" />
        <Route component={Resources} exact path="/resources" />
        <Route component={Profile} exact path="/profile" />
        <Route component={Research} path="**" />
        {/* <Redirect to="**" /> */}
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
