import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import HeaderPage from '../components/header-page'
import FooterPage from '../components/footer-page'
import './register.css'

const Register = (props) => {
  return (
    <div className="register-container">
      <Helmet>
        <title>Register - B3Science</title>
        <meta
          name="description"
          content="Laboratory of Brain Body and Behavior. Courses and resources for cognitive and computational neuroscience analysis."
        />
        <meta property="og:title" content="Register - B3Science" />
        <meta
          property="og:description"
          content="Laboratory of Brain Body and Behavior. Courses and resources for cognitive and computational neuroscience analysis."
        />
      </Helmet>
      <HeaderPage rootClassName="header-page-root-class-name5"></HeaderPage>
      <div className="register-banner">
        <h1 className="register-text">Register</h1>
        <div className="register-banner1">
          <input type="text" placeholder="email" className="input" />
          <input type="text" placeholder="password" className="input" />
          <input type="text" placeholder="repeat password" className="input" />
          <Link to="/courses" className="register-navlink button">
            <span>
              <span className="register-text2">Register</span>
              <br></br>
            </span>
          </Link>
        </div>
      </div>
      <FooterPage rootClassName="footer-page-root-class-name4"></FooterPage>
    </div>
  )
}

export default Register
