import React from 'react'

import { Helmet } from 'react-helmet'

import HeaderPage from '../components/header-page'
import HeadTitle from '../components/head-title'
import BlogPostCard21 from '../components/blog-post-card21'
import FooterPage from '../components/footer-page'
import './courses.css'

const Courses = (props) => {
  return (
    <div className="courses-container">
      <Helmet>
        <title>Courses - B3Science</title>
        <meta
          name="description"
          content="Laboratory of Brain Body and Behavior. Courses and resources for cognitive and computational neuroscience analysis."
        />
        <meta property="og:title" content="Courses - B3Science" />
        <meta
          property="og:description"
          content="Laboratory of Brain Body and Behavior. Courses and resources for cognitive and computational neuroscience analysis."
        />
      </Helmet>
      <HeaderPage rootClassName="header-page-root-class-name2"></HeaderPage>
      <HeadTitle heading="Courses"></HeadTitle>
      <div className="courses-container1">
        <div className="courses-hero">
          <div className="courses-container2">
            <span className="courses-text">Courses are coming soon!</span>
            <img
              alt="image"
              sizes="(min-width: 992px) 1200px, (min-width: 768px) 800px, 480px"
              src="/lineslogo-1500w.webp"
              srcSet="/lineslogo-1500w.webp 1200w, /lineslogo-tablet.webp 800w, /lineslogo-mobile.webp 480w"
              className="courses-image"
            />
          </div>
        </div>
      </div>
      <div className="courses-blog">
        <BlogPostCard21
          imageSrc="https://images.unsplash.com/photo-1519699047748-de8e457a634e?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIxfHxwb3J0cmFpdCUyMHdvbWFuJTIwY29sb3J8ZW58MHx8fHwxNjI2NDUwNTU4&amp;ixlib=rb-1.2.1&amp;h=1000"
          profileSrc="https://images.unsplash.com/photo-1531123897727-8f129e1688ce?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE0fHxwb3J0cmFpdCUyMHdvbWFufGVufDB8fHx8MTYyNjQ1MDU4MQ&amp;ixlib=rb-1.2.1&amp;h=1200"
          rootClassName="rootClassName2"
        ></BlogPostCard21>
        <BlogPostCard21
          profileSrc="https://images.unsplash.com/photo-1611232658409-0d98127f237f?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIzfHxwb3J0cmFpdCUyMHdvbWFufGVufDB8fHx8MTYyNjQ1MDU4MQ&amp;ixlib=rb-1.2.1&amp;h=1200"
          rootClassName="rootClassName3"
        ></BlogPostCard21>
      </div>
      <FooterPage rootClassName="footer-page-root-class-name1"></FooterPage>
    </div>
  )
}

export default Courses
