import React from 'react'

import { Helmet } from 'react-helmet'

import HeaderPage from '../components/header-page'
import HeadTitle from '../components/head-title'
import ResearchCard from '../components/research-card'
import FooterPage from '../components/footer-page'
import './research.css'

const Research = (props) => {
  return (
    <div className="research-container">
      <Helmet>
        <title>Research - B3Science</title>
        <meta
          name="description"
          content="Laboratory of Brain Body and Behavior. Courses and resources for cognitive and computational neuroscience analysis."
        />
        <meta property="og:title" content="Research - B3Science" />
        <meta
          property="og:description"
          content="Laboratory of Brain Body and Behavior. Courses and resources for cognitive and computational neuroscience analysis."
        />
      </Helmet>
      <HeaderPage rootClassName="header-page-root-class-name6"></HeaderPage>
      <HeadTitle
        heading="Research topics"
        rootClassName="head-title-root-class-name1"
      ></HeadTitle>
      <div className="research-blog">
        <ResearchCard
          label="LB3"
          title="Experience and visual perception"
          author="Emotion"
          imageSrc="/research/fig4web-1500w.webp"
          description="Traces of experiences, good and bad, are stored throughout the brain and body. We study the way visual cortical neurons adapt to environmental challenge, implemented for instance as fear conditioning in the laboratory.Amplitude of grating-evoked visual brain potentials before and after one grating (the threat cue) is paired with an unpleasant loud noise (e.g., Miskovic &amp; Keil, 2013, Keil et al., 2013)."
          rootClassName="rootClassName1"
        ></ResearchCard>
        <ResearchCard
          label="LB3"
          title="Distraction and competition"
          imageSrc="/research/966a16_31949a8acca643f285c48268a1b3eb33~mv2-1500w.gif"
          description="Our perceptual systems have limited capacity but are constantly exposed to a barrage of information conveyed by the senses. In a constantly changing and complex visual world, attention is needed to focus on relevant information while ignoring other things This is often a challenge, especially over longer periods of time (e.g., Wieser &amp; Keil, 2011). For instance, one may try to do homework but become distracted by a cartoon advertisement on a website or by the stock ticker scrolling on bottom of the screen. Engaging, emotionally arousing stimuli are particularly powerful distractors. We examine the extent, time course, and cortical mechanisms of distraction. An example paradigm used in our laboratory to assess competition effects between different stimuli over time is the attentional blink task (e.g., Keil &amp; Ihssen, 2004; Heim &amp; Keil, 2012)."
          rootClassName="rootClassName2"
        ></ResearchCard>
        <ResearchCard
          label="LB3"
          title="Oscillatory Brain Activity and Behavior"
          imageSrc="/research/cohpleasbsl_0002-1500w.webp"
          description="The human brain is one of the most complex systems known. Although no comprehensive theory of brain function exists, there is an emerging consensus that it is best conceived as a flexible and dynamic, massively parallel system, in which behavior and cognition emerge through oscillatory communication among units and subsystems. We study oscillatory brain dynamics using dense-array electrophysiology and simultaneous EEG-fMRI recordings in humans (e.g. Liu et al. 2012) and develop methods to better quantify and characterize oscillatory brain dynamics in the context of perception, emotion, and learning (e.g., Miskovic &amp; Keil, 2014). Method development greatlt benefits from our ongoing collaborations with Drs. Ding and Principe, UF College of Engineering. Phase synchrony of neural population activity between visual cortex and right hemisphere cortial regions in parietal and fronto-temporal areas, when viewing emotionally engaging pictures (from Keil et al. 2012)."
          rootClassName="rootClassName"
        ></ResearchCard>
        <ResearchCard
          title="Psychophysiology of emotion, fear, and anxiety: Interindividual differences"
          imageSrc="/research/socialanxiety-1500w.webp"
          description="Many psychiatric disorders are characterized by dysregulation of emotional reactivity, for instance when patients are exposed to potentially threatening situations. Using measures of brain function, autonomic ractivity, behaviroral responses, and self report, we examine how biomarkers of emotional processing in the laboratory can be used to characterize self-reported problems associated with disorders of mood and anxiety (e.g., Moratti et al., 2008). Establishing reliable and valid quantitative indices of inter-individual differences in affective reactivity is a necessary step towards objective assessment of the brain circuits underlying emotional dysfunction in anxiety and mood disorders, and ultimately to inform evidence-based intervention and treatment efforts. Reports of social anxiety co-vary with the tendency to pay attention to faces showing strong emotional expressions, as shown by a relation between visual amplification of angry faces in socially anxious individuals (left panel), which is bigger in those who endorse social anxiety symptoms (e.g., McTeague et al., 2011; Wieser et al., 2011)."
          rootClassName="rootClassName3"
        ></ResearchCard>
      </div>
      <FooterPage rootClassName="footer-page-root-class-name5"></FooterPage>
    </div>
  )
}

export default Research
