import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'

import HeaderPage from '../components/header-page'
import HeadTitle from '../components/head-title'
import PeopleCard from '../components/people-card'
import FooterPage from '../components/footer-page'
import './people.css'

import axios from 'axios';


const People = (props) => {



  const [dataF, setDataF] = useState([]);
  const [dataP, setDataP] = useState([]);
  const [dataG, setDataG] = useState([]);
  const [dataB, setDataB] = useState([]);
  const [dataC, setDataC] = useState([]);

  useEffect(() => {
      axios.get('http://api.brainbodybehavior.science/api/user/people/')
          .then(response => {
              const dataF = response.data.filter(item => item.people_status === 'F');  
              const dataP = response.data.filter(item => item.people_status === 'P');
              const dataG = response.data.filter(item => item.people_status === 'G');
              const dataB = response.data.filter(item => item.people_status === 'B');
              const dataC = response.data.filter(item => item.people_status === 'C');
              setDataF(dataF);
              setDataP(dataP);
              setDataG(dataG);
              setDataB(dataB);
              setDataC(dataC);
          })
          .catch(error => {
              console.error('Hubo un error al obtener los datos:', error);
          });
  }, []);




  return (
    <div className="people-container">
      <Helmet>
        <title>People - B3Science</title>
        <meta
          name="description"
          content="Laboratory of Brain Body and Behavior. Courses and resources for cognitive and computational neuroscience analysis."
        />
        <meta property="og:title" content="People - B3Science" />
        <meta
          property="og:description"
          content="Laboratory of Brain Body and Behavior. Courses and resources for cognitive and computational neuroscience analysis."
        />
      </Helmet>
      <HeaderPage rootClassName="header-page-root-class-name4"></HeaderPage>
      <HeadTitle
        heading="People"
        rootClassName="head-title-root-class-name2"
      ></HeadTitle>
      <div className="people-container1">
        <h1 className="people-text">Faculty</h1>


        {dataF.map((item, index) => (<>
              <PeopleCard
              key={index}
              quote={item.bio}
              name={item.username}
              name1={item.status}
              profileSrc={item.avatar}
              curriculumvitae = {item.curriculumvitae}
              rootClassName="rootClassName1"
              ></PeopleCard>
              <br></br>
            </>))}


        {/* <PeopleCard
          name="Andreas Keil"
          name1="Principal Investigator"
          profileSrc="https://images.unsplash.com/photo-1614630982169-e89202c5e045?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIwfHxtYWxlJTIwcG9ydHJhaXR8ZW58MHx8fHwxNjI2NDUyMTk4&amp;ixlib=rb-1.2.1&amp;h=1200"
          rootClassName="rootClassName1"
        ></PeopleCard> */}
      </div>
      <div className="people-container2">
        <h1 className="people-text1">Post-Doctoral associates</h1>
        {dataP.map((item, index) => (<>
              <PeopleCard
              key={index}
              quote={item.bio}
              name={item.username}
              name1={item.status}
              profileSrc={item.avatar}
              rootClassName="rootClassName1"
              ></PeopleCard>
              <br></br>
            </>))}
      </div>
      <div className="people-container3">
        <h1 className="people-text2">Graduate Students</h1>
        {dataG.map((item, index) => (<>
              <PeopleCard
              key={index}
              quote={item.bio}
              name={item.username}
              name1={item.status}
              profileSrc={item.avatar}
              rootClassName="rootClassName1"
              ></PeopleCard>
              <br></br>
            </>))}
      </div>
      <div className="people-container4">
        <h1 className="people-text3">Post-baccalaureate</h1>
        {dataB.map((item, index) => (<>
              <PeopleCard
              key={index}
              quote={item.bio}
              name={item.username}
              name1={item.status}
              profileSrc={item.avatar}
              rootClassName="rootClassName1"
              ></PeopleCard>
              <br></br>
            </>))}
      </div>
      <div className="people-container5">
        <h1 className="people-text4">Collaborators</h1>
        {dataC.map((item, index) => (<>
              <PeopleCard
              key={index}
              quote={item.bio}
              name={item.username}
              name1={item.status}
              profileSrc={item.avatar}
              rootClassName="rootClassName1"
              ></PeopleCard>
              <br></br>
            </>))}
      </div>
      <FooterPage rootClassName="footer-page-root-class-name3"></FooterPage>
    </div>
  )
}

export default People
